<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            type="year"
            default="today"
            label="년도"
            name="years"
            v-model="searchParam.years"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            stepperGrpCd="HEA_MUSCLE_SURVEY_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="heaMuscleSurveyStepCd"
            label="진행단계"
            v-model="searchParam.heaMuscleSurveyStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.heaMuscleSurveyStepCd)]">
                    {{ props.row.heaMuscleSurveyStepName }}
                  </div>
                  <div :class="['text-grid-etc', 'txt-box-grid', 'text-gray-box']">
                    {{ props.row.year }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.investigationPlanName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.processSopNames }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'symptomSurvey',
  data() {
    return {
      searchParam: {
        plantCd: null,
        years: [],
        heaMuscleSurveyStepCd: null,
        userId: '',
      },
      grid: {
        columns: [
          {
            name: 'investigationPlanName',
            field: 'investigationPlanName',
            label: '조사계획명',
            align: 'left',
            sortable: false,
            style: 'width:250px',
          },
          {
            name: 'year',
            field: 'year',
            label: '년도',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'heaMuscleSurveyStepName',
            field: 'heaMuscleSurveyStepName',
            label: '설문지 작성현황',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'processSopNames',
            field: 'processSopNames',
            label: '관련 공정/단위작업',
            align: 'left',
            sortable: false,
            style: 'width:250px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSurvey.list.url
      // code setting
      this.$set(this.searchParam, 'userId', this.$store.getters.user.userId);
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'MS00000001': // 작성중
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'MS00000005': // 설문완료
          cls = 'txt-box-grid text-primary-box';
          break;
      }
      return cls;
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      this.popupOptions.title = '근골격계질환 증상조사표 현황';
      this.popupOptions.param = {
        heaMuscleWorkerSurveyId: row.heaMuscleWorkerSurveyId,
      };
      this.popupOptions.target = () => import(`${'./symptomSurveyDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
